@tailwind base;
@tailwind components;
@tailwind utilities;

ul {
  margin-left: 20px;
}

li {
  list-style-type: none;
  margin-bottom: 10px;
}

p {
  text-align: left;
}

h1 {
  font-size: 30px;
  font-family: "Manrope";
  font-weight: 800;
  color: #000;
  margin-bottom: 30px;
}

h2 {
  text-decoration: underline;
}

section {
  margin-bottom: 30px;
}

.site-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

header {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.header-right-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer {
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.btn-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  padding: 10px 25px;
  font-family: "Manrope";
  border-radius: 53px;
  cursor: pointer;
  border: 1px solid black;
  margin-left: 10px;
}

.btn {
  width: 100%;
  border-radius: 53px;
  height: 106px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 41px;
  margin-bottom: 40px;
  font-family: "Manrope";
  cursor: pointer;
}

.btn-find {
  background-color: #f2b0bc;
  color: #000;
}

.btn-find img,
.btn-found img {
  margin-right: 30px;
}

.btn-found {
  background-color: #e63c2c;
  color: #fff;
}

.btn-bg-white {
  background-color: #fff;
  color: #000;
}

.btn-border-white {
  border: 1px solid #fff;
  color: #fff;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px;
}

.container-index{
  height: calc(100vh - 320px);
}

.content p {
  margin-bottom: 20px;
}

.btn-insta {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  padding: 10px 25px;
  font-family: "Manrope";
  border-radius: 53px;
  cursor: pointer;
  border: 1px solid black;
  margin-left: 10px;
}

.bottom-right-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-search-person {
  width: 100%;
  height: 106px;
  border-radius: 53px;
  border: 1px solid #000;
  padding: 0 30px;
  font-size: 31px;
  font-family: "Manrope";
  margin-bottom: 40px;
  background-color: #f2b0bc;
  color: #000;
}

.input-search-person::placeholder {
  color: #000;
}

.btn-find-bg-white {
  background-color: #fff;
  color: #000;
}

.person-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.person-list-container li {
  margin-right: 15px;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 15px;
}

.person-list-container li .name {
  font-size: 20px;
  font-family: "Manrope";
  margin-bottom: 20px;
}

.person-list-container li p {
  font-size: 20px;
  font-family: "Manrope";
  background-color: #f6dac3;
  border-radius: 10px;
  padding: 5px 15px;
  margin-bottom: 10px;
}

.person-list-container li .btn-found {
  border-radius: 5px;
  padding: 5px 15px;
  text-align: center;
}

.searchbox-listed {
  display: flex;
  width: 100%;
}

.lgpd {
  align-items: flex-start !important;
}

.lgpd h2 {
  font-weight: bold;
  margin-bottom: 30px;
}

.found-container {
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  background-color: #e63c2c;
}

.height-founded {
  height: calc(100vh - 350px);
}

.form-area {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  margin-top: 80px;
}

.form-grid {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 32px;
  padding: 0 20px;
}

.three-columns {
  grid-template-columns: 2fr 1fr 2fr;
}

.two-columns {
  grid-template-columns: repeat(2, 1fr);
}

.two-columns-first-largest {
  grid-template-columns: 2fr 1fr;
}

.one-column {
  grid-template-columns: 1fr;
}

.btn-found-form {
  width: 100%;
  max-width: 1200px;
  padding: 0 40px;
  height: 110px;
  border-radius: 90px;
  background-color: #f8f8f8;
  color: #000;
  font-size: 200%;
  font-family: "Manrope";
  cursor: pointer;
  border: none;
  outline: none;
  gap: 20px;
}

@media (max-width: 894px) {
  .three-columns,
  .two-columns, .two-columns-first-largest {
    grid-template-columns: 1fr;
  }
}

.input-file {
  display: none;
}

.input-select-items{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 110px;
  padding: 30px 50px;
  gap: 24px;
  border-radius: 90px;
  border: 2px #f8f8f8 solid;
  background: #e63c2c;

  outline: none;

  font-family: "Manrope";
  font-size: 35px;
  color: #f8f8f8;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


.input-select-items::placeholder {
  color: #f8f8f8;
}

.form-select {
  position: relative;
}

.form-select:before {
  position: absolute;
  top: 40%;
  right: 40px;
  content: " ";
  width: 35px;
  height: 35px;
  margin-top: -5px;
  pointer-events: none;

  background-image: url("./assets/img/arrow-down.svg");
}

.input-select-items::-webkit-outer-spin-button,
.input-select-items::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.cursor-pointer {
  cursor: pointer;
}

.disabled {
  opacity: 0.2;
  cursor: default;
}

.options {
  font-family: "Manrope";
  font-size: 35px;
  color: #f8f8f8;
  background: #e63c2c;
}

.founded-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  gap: 30px;
}

.register-succefull {
  font-family: "Manrope";
  font-size: 35px;
  font-weight: 400;
  line-height: 47.81px;
  text-align: center;
  color: #f8f8f8;
}

.policies {
  align-items: flex-start;
}

.policies h2 {
  font-weight: bold;
  margin-bottom: 30px;
}

.full-h {
  height: 100vh;
} 